import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import kt1 from '../../../images/parklak1.gif'
import kt2 from '../../../images/parklak2.gif'
import kt3 from '../../../images/parklak3.gif'
import kt4 from '../../../images/parklak4.gif'
import kt5 from '../../../images/parklak5.gif'
import kt6 from '../../../images/parklak6.gif'
import kt7 from '../../../images/parklak7.gif'
import kt8 from '../../../images/parklae1.gif'
import kt9 from '../../../images/parklaz1.gif'


const kogunemiseTyybid = () => (
  <Layout>
    <SEO title="Siirdeseinte kogunemise tüübid" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>KOGUNEMISE TÜÜBID</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

        <div className="wrapper-epo">
            <Link to='/ruumijagajad/voldikuksed/'>
            <div className="epo">
                <h2>Voldikuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/lootsuksed/'>
            <div className="epo">
                <h2>Lõõtsuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/siirdeseinad/'
            activeStyle={{ borderBottom: '3px solid #ffa609' }}
            partiallyActive={true}>
            <div className="epo">
                <h2>Siirdeseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/voldikseinad/'> 
            <div className="epo">
                <h2>Voldikseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/referentsid/'>
            <div className="epo">
                <h2>Referentsid</h2>
            </div>
            </Link>
         </div>

      <div className="kt-wrapper">

        <img src={kt1} alt="Tabel" width="100px"></img>
        <img src={kt2} alt="Tabel" width="100px"></img>
        <img src={kt3} alt="Tabel" width="100px"></img>
        <img src={kt4} alt="Tabel" width="100px"></img>
        <img src={kt5} alt="Tabel" width="100px"></img>
        <img src={kt6} alt="Tabel" width="100px"></img>
        <img src={kt7} alt="Tabel" width="100px"></img>
        <img src={kt8} alt="Tabel" width="100px"></img>
        <img src={kt9} alt="Tabel" width="100px"></img>

      </div>

  </div>

  </Layout>
)

export default kogunemiseTyybid